@import '@angular/material/prebuilt-themes/deeppurple-amber';
/* You can add global styles to this file, and also import other style files */
body {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Quicksand';
    src: url('/assets/font/Quicksand-VariableFont_wght.ttf');
  }

